body {
    background: #f5f5f5 !important;
}
.campaignWrapper {
    max-width: 1440px;
    margin: auto;
    background: #fff;
    min-height: 100vh;
    box-shadow: 0px 0px 10px #cccccc50;
    padding-bottom: 100px;
}
.topHeader {
    padding: 16px;
    text-align: center;
    background: #fd5f07;
}
.topHeader h3 {
    font-size: 21px;
    font-weight: normal;
    color: #fff;
    margin: 0px;
    padding: 0px;
}
.banner {
    padding: 32px 0px;
}
.banner h3 {
    font-size: 35px;
    font-weight: normal;
    text-align: center;
    line-height: 43px;
}
.banner h3 span {
    color: #fd5f07;
}

.banner h4 {
    font-size: 25px;
    font-weight: 200 !important;
    text-align: center;
    line-height: 40px;
}
.banner h4 span {
    color: #fd5f07;
}
.campaignWrapper .btn.btn-outline-primary {
    color: #fff;
    border: 1px solid #fd5f07;
    font-size: 24px;
    padding: 8px 16px;
    background: #fd5f07;
}
.campaignWrapper .btn.btn-outline-primary:hover {
    background: #fff;
    color: #fd5f07;
}

.painPoints {
    padding: 64px 0px;
    background: #fd5f0720;
    /* margin: 32px 0px 0px 0px; */
}
.painPoints h1 {
    font-size: 31px;
    color: #fd5f07;
    font-weight: bold;
    margin-bottom: 32px;
}

.painPoints ul {
    margin: 0px;
    padding: 0px;
}
.painPoints ul li {
    list-style-type: none;
    padding: 0px 15px 15px 0px;
    margin-bottom: 15px;
    border-bottom: 1px solid #fd5f0750;
}
.painPoints ul li p {
    margin: 0px;
    padding: 0px;
    color: #00000095;
}
.painPoints ul li h4 {
    font-size: 21px;
    font-weight: bold;
    margin: 0px;
    padding: 0px 0px 5px 0px;
}
.painPoints ul li:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}
.callout {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
    z-index: 1000;
    padding: 16px;
    background: #ffebe0;
    box-shadow: 0px 0px 10px #33333330;
}

.masterclass {
    padding: 32px 0px;
}
.masterclass h1 {
    font-size: 31px;
    color: #333;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
}

.masterclass ol li {
    display: block;
    box-shadow: 0px 0px 3.5px #ccc;
    padding: 16px 0px 16px 64px;
    margin-bottom: 16px;
    font-size: 18px;
}

.masterclass ol {
    list-style: none; /* Remove default numbering */
    counter-reset: list-counter; /* Initialize counter */
    margin: 0px;
    padding: 0px;
}

.masterclass ol li {
    counter-increment: list-counter; /* Increment counter */
    position: relative;
}

.masterclass ol li::before {
    content: counter(list-counter) "."; /* Add counter before the item */
    position: absolute;
    left: 0px;
    font-weight: bold;
    color: #fff; /* Customize color */
    background: #fd5f07;
    padding: 16px 20px;
    height: 100%;
    top: 0px;
}

/*  */

.aboutTrainers {
    padding: 32px 0px;
    background: #333333;
    color: #fff;
}
.aboutTrainers h1 {
    font-size: 31px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 36px;
    text-align: center;
}

.aboutTrainers h3 {
    font-size: 21px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
}
.aboutTrainers ol {
    margin: 0px;
    padding: 0px;
}
.aboutTrainers ol li {
    display: block;
    box-shadow: 0px 0px 3.5px #ccc;
    padding: 16px 0px 16px 64px;
    margin-bottom: 16px;
    font-size: 18px;
}

.aboutTrainers ol {
    list-style: none; /* Remove default numbering */
    counter-reset: list-counter; /* Initialize counter */
}

.aboutTrainers ol li {
    position: relative;
}
.aboutTrainers ol li span {
    position: absolute;
    left: 0px;
    top: 0px;
    background: #fff;
    padding: 16px;
    height: 100%;
}

.test {
    background: #fff;
    padding: 100px 0px 32px 0px;
}
.test .inner strong {
    color: #fd5f07;
}
.test .inner {
    padding: 16px;
    background: #fd5f0710;
    height: 100%;
    font-size: 21px;
    font-weight: 200 !important;
    line-height: 30px;
    position: relative;
    padding-top: 80px;
}
.profilePhoto {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 150px;
    margin: -75px 0 0 10px;
}
.profilePhoto img {
    border-radius: 100%;
    border: 1px solid #ccc;
    padding: 5px;
    background: #fff;
}
.test .inner p {
    font-size: 21px;
    font-weight: 200 !important;
    line-height: 30px;
}
.test .inner p:last-child {
    margin: 0px;
    padding: 0px;
}
.faqs{padding: 40px 0px; background: #f5f5f5;}
.faqs h2 {
    font-size: 31px;
    color: #333;
    font-weight: bold;
    text-align: center;
}
.faqs ul {
    margin: 0px;
    padding: 0px;
}
.faqs ul li {
    display: block;
    padding: 20px 0px;
    border-bottom: 2px dotted #ccc;
}
.faqs ul li p {
    margin: 0px;
    padding: 0px;
    color: #00000099;
}
.faqs ul li h4 {
    font-size: 21px;
    color: #333333;
}
.trainerPlaceholder {
    text-align: center;
    margin-bottom: 25px;
    max-width: 300px;
    margin: auto;
    margin: 40px auto 10px auto;
}
.trainerPlaceholder img {
    border-radius: 100%;
    border: 5px solid #fff;
}
.white {
    color: #fff;
    text-align: center;
}
.testimonials {
    padding: 30px 0px;
}
.testimonials h2 {
    font-size: 40px;
    text-align: center;
}
.testimonials .inner {
    padding: 5px;
    background: #fff;
    border: 2px dashed #ccc;
}

.benefitWorkshop {
    padding: 30px 0px;
    background: #fd5f0720;
}

.benefitWorkshop h1 {
    font-size: 31px;
    color: #fd5f07;
    font-weight: bold;
    margin-bottom: 32px;
}

.benefitWorkshop .innerBox {
    position: relative;
    border-radius: 20px;
    border: 1px dashed #ccc;
    padding: 5px;
}
.benefitWorkshop .innerBox img {
    border-radius: 20px;
}
.benefitWorkshop h2 {
    position: absolute;
    bottom: 0;
    font-size: 24px;
    color: #000;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
    width: calc(100% - 10px);
    padding: 10px;
    background: #f5f5f590;
    z-index: 1000;
}
.workshopDetails {
    background: #342e39;
    padding: 45px;
    color: #fff;
}
.workshopDetails h1 {
    font-size: 31px;
    color: #fff;
    font-weight: bold;
    margin: 16px 0px;
}
.workshopDetails ul {
    margin: 0px;
    display: block;
    padding: 0px;
}
.workshopDetails ul li {
    display: block;
    width: 44%;
    float: left;
    padding: 15px;
    background: #fff;
    color: #333;
    margin: 0px 3% 3% 0px;
    text-align: center;
    border-radius: 16px;
    font-size: 18px;
    font-weight: bold;
}
.workshopDetails ul li span {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 24px;
}
.workshopDetails ul li span img {
    margin-right: 10px;
}

ul.list4 li {
    width: 20%;
    font-size: 30px;
    font-weight: bold;
    color: #fd5f07;
}
ul.list4 li span {
    font-size: 18px;
    font-weight: normal;
    color: #333;
}

@media only screen and (max-width: 999px) {
    .topHeader {
        padding: 8px;
    }
    .topHeader h3 {
        font-size: 18px;
        line-height: 26px;
    }
    .banner h4 {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 25px;
    }
    .painPoints {
        margin: 0px;
        padding: 25px 0px;
    }
    .painPoints h1 {
        font-size: 25px;
        margin-bottom: 16px;
        text-align: center;
        padding-bottom: 20px;
    }
    .painPoints ul li h4 {
        font-size: 18px;
    }
    .masterclass h1 {
        font-size: 25px;
    }
    .masterclass ol li {
        font-size: 15px;
        line-height: 20px;
    }
    .aboutTrainers h1 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .trainerPlaceholder {
        margin: 0px auto;
        padding: 0px;
    }
    .faqs h2 {
        font-size: 25px;
    }
    .faqs ul li h4 {
        font-size: 18px;
    }
    .campaignWrapper .btn.btn-outline-primary {
        font-size: 18px;
    }
    .testimonials h2 {
        font-size: 25px;
    }
    .workshopDetails h1 {
        margin: 16px 0px;
    }
    /* .workshopDetails ul li {
        width: 100%;
    } */
    .mobile-mergin-bottom {
        margin-bottom: 100px;
    }
    .banner h3 {
        line-height: 35px;
        margin-bottom: 0px;
    }
    .banner br {
        display: none !important;
    }
    .hide-mobile {
        display: none;
    }

    .workshopDetails h1 {
        text-align: center;
        font-size: 25px;
    }
    .workshopDetails ul li {
        font-size: 16px;
        width: 47%;
    }

    .workshopDetails {
        padding: 20px 0px;
    }
    .workshopDetails ul li {
        padding: 8px;
        height: 100px;
        text-align: center;
        padding-top: 20px;
    }
    .workshopDetails ul li:first-child{ padding-top: 10px;}
    .workshopDetails ul.list4 li{ width: 22.5%; font-size: 20px; font-weight: bold; height: 65px; padding: 8px;}
    .workshopDetails ul.list4 li:first-child{ padding-top: 8px;}
    .workshopDetails ul.list4 li:last-child{ margin-right:0px;}
    .workshopDetails ul li span {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .trainerPlaceholder{ margin-bottom: 15px;}
    .aboutTrainers .white{ font-size: 21px;}
}
